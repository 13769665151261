<template>
  <div class="header">
    <div class="d-flex align-items-start justify-content-between flex-column flex-md-row">
      <div class="logo">
        <router-link to="/">
          <b-img src="/images/logo.png" alt="Cash Box Credit Union" fluid />
        </router-link>
        <p class="my-1 my-sm-3 text-success">
          The credit union for people who live or work in Tameside and Glossop
        </p>
      </div>
      <div class="cash-box-nav-wrapper d-flex flex-column flex-lg-row align-items-center pl-0 pl-md-4 pl-lg-0">
        <div class="cash-box-feedback px-0 px-lg-3 pb-2 pb-lg-0">
          <a href="https://uk.1872culture.com/survey/member_always-on_temperature_check-cash_box_credit_union-temp-202407171346" target="_blank">
            <b-img rounded src="/misc/feedback-banner.png" alt="Feedback" fluid />
          </a>
        </div>
        <div class="cash-box-actions text-right mt-0 mt-sm-0 w-auto">
            <b-button
                block
                variant="primary"
                href="https://cashbox.cuaccount.com/login/"
                target="_blank"
                class="mb-2 text-uppercase text-nowrap"
                v-b-tooltip.hover.left
                title="Existing members already registered please log in"
            >Login/Members Area</b-button>
            <b-button
                block
                variant="success"
                to="/join-us"
                class="mb-2 text-uppercase text-nowrap"
                v-b-tooltip.hover.left
                title="Join Us"
            >Join Cash Box</b-button
            >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header"
}
</script>

<style scoped>
.header .cash-box-actions .btn {
  font-size:0.9em;
}
.header .cash-box-nav-wrapper {
  width: auto;
}
.header .cash-box-feedback img {
  max-height: 150px;
}

@media (max-width: 992px) {
  .header .cash-box-nav-wrapper {
    width: 100%;
  }
  .header .cash-box-actions  {
    width: 100% !important;
  }
}


</style>